import { MODULES } from '@/constants';
import { Module } from 'vuex';

const initialState = {
    urls: [],
} as IUrlCacheStatusState;

const getters = {
    [MODULES.URL_CACHE_CHECKER.STORE.GETTER.CACHE_URL_STATUS_COLLECTION]: (
        state: IUrlCacheStatusState
    ) => state.urls,
};

const mutations = {
    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION
        .PUSH_CACHE_URL_STATUS_COLLECTION]: (
        state: IUrlCacheStatusState,
        payload: IUrlIndexStatusItem[][]
    ) => {
        for (const group of payload) {
            state.urls = [...state.urls, ...group];
        }
    },

    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION
        .CLEAN_CACHE_URL_STATUS_COLLECTION]: (state: IUrlCacheStatusState) => {
        state.urls = [];
    },
};

const actions = {};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
} as Module<IUrlCacheStatusState, any>;
