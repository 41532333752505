import { MODULES } from '@/constants';
import { ActionContext, Module } from 'vuex';

const initialState = {
    urlList: [],
    fileIsReady: false,
} as IUploadedFileState;

const getters = {
    [MODULES.URL_CACHE_CHECKER.STORE.GETTER.URL_LIST]: (
        state: IUploadedFileState
    ) => [...state.urlList],

    [MODULES.URL_CACHE_CHECKER.STORE.GETTER.FILE_IS_READY]: (
        state: IUploadedFileState
    ) => state.fileIsReady,
};

const mutations = {
    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION.SET_URL_LIST]: (
        state: IUploadedFileState,
        urlListText: string
    ) => {
        state.urlList = [...urlListText.split('\n')];
    },

    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION.SET_FILE_IS_READY]: (
        state: IUploadedFileState,
        value: boolean
    ) => {
        state.fileIsReady = value;
    },
};

const actions = {
    [MODULES.URL_CACHE_CHECKER.STORE.ACTION.SET_SELECTED_FILE]: async (
        ctx: ActionContext<IUploadedFileState, unknown>,
        file: File
    ): Promise<void> => {
        ctx.commit(
            MODULES.URL_CACHE_CHECKER.STORE.MUTATION.SET_URL_LIST,
            await file.text()
        );
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
} as Module<IUploadedFileState, any>;
