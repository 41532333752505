import { MODULES } from '@/constants';
import cachedUrlCollectionStore from './store/cached-url-collection.store';
import cachedUrlRequestQueueStore from './store/cached-url-request-queue.store';
import uploadedFileStore from './store/uploaded-file.store';

export default {
    [MODULES.URL_CACHE_CHECKER.STORE.NAMESPACE
        .CACHED_URL_COLLECTION]: cachedUrlCollectionStore,
    [MODULES.URL_CACHE_CHECKER.STORE.NAMESPACE
        .CACHED_URL_REQUEST_QUEUE]: cachedUrlRequestQueueStore,
    [MODULES.URL_CACHE_CHECKER.STORE.NAMESPACE
        .UPLOADED_FILE]: uploadedFileStore,
};
