import { App } from '@/app.models';
import { MODULES } from '@/constants';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
    {
        path: '/url-cache',
        name: MODULES.URL_CACHE_CHECKER.ROUTE.NAME.URL_CACHE,
        component: () => import('@/modules/url-cache/views/UrlCacheView.vue'),
        meta: {
            anchor: 'Google cache',
            private: true,
            icon: 'mdi-google',
        } as App.RouteMetadata,
    },
];

export default routes;
