import { MODULES } from '@/constants';
import { Module } from 'vuex';

const initialState = {
    items: [],
    progressPercent: -1,
} as IUrlCacheRequestQueueState;

const getters = {
    [MODULES.URL_CACHE_CHECKER.STORE.GETTER.CACHE_URL_STATUS_REQUEST_QUEUE]: (
        state: IUrlCacheRequestQueueState
    ) => [...state.items],

    [MODULES.URL_CACHE_CHECKER.STORE.GETTER.CACHE_URL_STATUS_PROGRESS]: (
        state: IUrlCacheRequestQueueState
    ) => state.progressPercent,
};

const mutations = {
    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION
        .SET_CACHE_URL_STATUS_REQUEST_QUEUE]: (
        state: IUrlCacheRequestQueueState,
        payload: string[]
    ) => {
        state.items = [...state.items, ...payload] as string[][];
    },

    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION
        .SET_FETCHING_CACHE_URL_STATUS_PROGRESS]: (
        state: IUrlCacheRequestQueueState,
        progress: number
    ) => {
        state.progressPercent = progress;
    },

    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION
        .CLEAN_URL_CACHE_STATUS_REQUEST_QUEUE]: (
        state: IUrlCacheRequestQueueState
    ) => {
        state.items = [];
    },

    [MODULES.URL_CACHE_CHECKER.STORE.MUTATION
        .CLEAN_URL_GROUP_FROM_REQUEST_QUEUE]: (
        state: IUrlCacheRequestQueueState,
        index: number
    ) => {
        const items = [...state.items];
        items[index] = [];
        state.items = [...items];
    },
};

const actions = {};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
} as Module<IUrlCacheRequestQueueState, any>;
